<template>
  <div class="join">
    <!-- <my-swiper :bannerList="bannerList"></my-swiper> -->
    <img
      class="banner-item"
      :src="$store.getters.mobile ? bannerList[0].mobileImg : bannerList[0].img"
    />
    <div class="yxj-container" style="overflow: visible">
      <div class="join-container page-container swer">
        <div class="qr-area">
          <div class="qr-center mobile-qr box" v-for="(item, index) in pageObj" :key="index" @click="handlePage(index)">
            <div class="wx-tips">{{item.title}}</div>
            <div class="wx-label">
              <p v-if="!$store.getters.mobile" v-html="item.info"></p>
              <p v-else>{{item.mobileInfo}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Join',
  data () {
    return {
      pageObj: [
        {
          title: 'ESG报告下载',
          info: '我们深感在推动全球向碳中和转型中扮演着不可或缺的角色，这一转型不仅关乎环境保护、气候变化的应对，更是企业责任、社会责任乃至全球共同未来的体现。',
          mobileInfo: '我们深感在推动全球向碳中和转型中扮演着不可或缺的角色，这一转型不仅关乎环境保护、气候变化的应对，更是企业责任、社会责任乃至全球共同未来的体现。'
        },
        {
          title: 'CSR企业社会责任',
          info: '“医学界”作为国内头部医生服务平台，始终以“服务医者，改善医疗”为初心，发挥新媒体优势，链接多方社会力量，投身社会公益活动，积极履行企业社会责任。',
          mobileInfo: '“医学界”作为国内头部医生服务平台，始终以“服务医者，改善医疗”为初心，发挥新媒体优势，链接多方社会力量，投身社会公益活动，积极履行企业社会责任。'
        }
      ],
      bannerList: [
        {
          to: '/culture',
          img: require('@/assets/images/social-banner.png'),
          mobileImg: require('@/assets/images/social-bannerm.png'),
        }
      ],
    }
  },
  methods: {
    handlePage(index) {
      if(index === 0) {
        window.open('https://group.yxj.org.cn/%E3%80%8A%E5%8C%BB%E5%AD%A6%E7%95%8CESG%EF%BC%88%E7%8E%AF%E5%A2%83%E5%92%8C%E7%A4%BE%E4%BC%9A%E6%B2%BB%E7%90%86%EF%BC%89%E7%A2%B3%E5%87%8F%E6%8E%92%E7%9B%AE%E6%A0%87%E5%92%8C%E4%B8%BE%E6%8E%AA%E3%80%8B.pdf')
      } else if(index === 1) {
        // window.open('https://test.yxj.org.cn/articelList?title=CSR%E4%BC%81%E4%B8%9A%E7%A4%BE%E4%BC%9A%E8%B4%A3%E4%BB%BB&postition=2&deptID=0')
        window.open('https://www.yxj.org.cn/articelList?title=CSR企业社会责任&postition=2&deptID=0')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
